import React from "react";
import styles from "../../../css/crown.module.css";
import mgm from "../../../images/crown-residence/mgm-logo.png";

const PublicSpaces = () => {
  return (
    <section className={styles.publicSection}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-9 mb-5">
            <div className={styles.publicText}>Public Spaces Include</div>
            <ul className={styles.publicList}>
              <li className={styles.publicLi}>Community centres</li>
              <li className={styles.publicLi}>Parks</li>
              <li className={styles.publicLi}>Playgrounds</li>
              <li className={styles.publicLi}>
                A 8 room guesthouse is provided to support family parties and
                celebrations
              </li>
              <li className={styles.publicLi}>
                A convenience store catering day to day needs
              </li>
              <li className={styles.publicLi}>Juice pubs</li>
              <li className={styles.publicLi}>Pet's park</li>
              <li className={styles.publicLi}>
                Solar panels that power common areas
              </li>
            </ul>
          </div>
          <div className="col-md-3 mb-5 border-left">
            <div className="text-center">
              <div className="h5 mb-3 text-uppercase">
                multispeciality clinic and pharmacy by
              </div>
              <img src={mgm} className="mb-3" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PublicSpaces;
