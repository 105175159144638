import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Layout from "../common/Layout";
import styles from "../../css/crown.module.css";
import logo from "../../images/crown-residence/logo.png";
import {
  SectionCon,
  Synopsis,
  Gmap,
  Specifications,
  GallerySection,
  WalkThroughSection,
} from "./Project";
import Helmet from "react-helmet";
import Img from "gatsby-image";
import ResidentSection from "./ResidentSection";
import Highlights from "./crown/Highlights";
import PublicSpaces from "./crown/PublicSpaces";
import AcresText from "./crown/AcresText";
import { VideoBtn } from "./BannerSection";
import bannerImg from "../../images/crown-residence/video-poster.jpg";

const useCrownData = () =>
  useStaticQuery(graphql`
    query {
      green: file(
        relativePath: {
          eq: "on-going-projects/crown-residence/green-open-spaces/1.jpg"
        }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ongoingJson(slug: { eq: "crown-residence" }) {
        banner {
          childImageSharp {
            fluid {
              src
            }
          }
        }
        slug
        rera
        cc
        title
        tagline
        brouchureUrl
        logo {
          childImageSharp {
            fluid(maxWidth: 350) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        video {
          type
          link
        }
        sections {
          title
          synopsis
          specification {
            a
            q
          }
          covers {
            link
            image {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          highlights
          features {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            points
          }
          gMapUrl
          address
          gallery {
            title
            images {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          project_updates {
            title
            images {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            videos {
              type
              thumbnail {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              link
            }
          }
          walkthrough {
            title
            urls
          }
        }
      }
    }
  `);

const CrownResidences = () => {
  const { ongoingJson } = useCrownData();
  const project = ongoingJson;
  const sections = project.sections || [];
  const current = sections[0] || {};

  return (
    <Layout>
      <Helmet>
        <title>{project.title}</title>
        <meta name="description" content={project.tagline} />
        <meta property="og:type" content="website" />
        {project.banner && project.banner.childImageSharp && (
          <meta
            property="og:image"
            content={project.banner.childImageSharp.fluid.src}
          />
        )}
      </Helmet>
      <div className={styles.crownBannerSection}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5 mb-3 mb-md-0">
              <div
                className={`${styles.crownBannerContent} align-items-center d-flex flex-column text-center align-items-md-start text-md-left`}
              >
                <img className="mb-3" src={logo} width="200" alt="" />
                <div className={styles.crownBannerTitle}>
                  A SELF CONTAINED EPIC LIFESTYLE WITH SMARTLY CURATED LIVING
                  EXPERIENCES
                </div>
                <a
                  href={project.brouchureUrl}
                  className={`btn ${styles.crownBroBtn} mb-2`}
                  download="crown-residence"
                  target="_blank"
                  rel="noreferrer"
                >
                  Download Brochure
                </a>
                <div style={{ fontSize: "10px" }}>{project.rera}</div>
                <div style={{ fontSize: "10px" }}>{project.cc}</div>
              </div>
            </div>
            <div className="col-md-7 mb-3 mb-md-0">
              <div className={`${styles.crownVideo}`}>
                <img src={bannerImg} alt="" />
                <div className="abs-full d-flex justify-content-end align-items-start pr-5 pt-4">
                  <VideoBtn video={project.video} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SectionCon>
        <Synopsis synopsis={current.synopsis} />
      </SectionCon>
      <AcresText />
      <Highlights features={current.features} />
      <PublicSpaces />
      <SectionCon>
        <Gmap
          gMapUrl={current.gMapUrl}
          projectLogo={project.logo}
          address={current.address}
        />
        <Specifications specification={current.specification} />
        <ResidentSection id="floor-plan" title="Floor Plan">
          <div className="form-row">
            {current.covers.map((x, idx) => (
              <div className="col-md col-6 mb-2" key={idx}>
                <a href={x.link} target="_blank" rel="noreferrer">
                  <Img fluid={x.image.childImageSharp.fluid} />
                </a>
              </div>
            ))}
          </div>
        </ResidentSection>
        <WalkThroughSection walk_through={current.walkthrough} />
        <GallerySection gallery={current.gallery} />
        <GallerySection
          title="Project Status"
          gallery={current.project_updates}
        />
      </SectionCon>
    </Layout>
  );
};

export const PanaromaImages = ({ images = [] }) => {
  return images.map(({ node }) => (
    <div className="col-md-3 col-6 mb-2" key={node.id}>
      <Link to={`/360-image-viewer?image=${node.childImageSharp.original.src}`}>
        <Img fluid={node.childImageSharp.fluid} alt="" />
      </Link>
    </div>
  ));
};

export default CrownResidences;
